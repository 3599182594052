.notFoundPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #141D24;
    height: 100vh;

    .textContainer {
        padding: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        h3 {
            font-size: 24px;
        }
        a {
            text-decoration: none;
            color: #99E0FF;
        }
    }

    p, h3{
        color: #E1EDF7;
    }

    
}