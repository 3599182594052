.navBarContainer {
    background-color: #1c2933;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;

    .logoContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: 48px;
        font-size: 18px;
        width: 40%;
        .logo{
            height: 45px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .nameContainer {
        .name {
            display: none;
        }
    }
    .optionsContainer {
        display: flex;
        width: 60%;
        max-width: 1200px;
        justify-content: space-evenly;
        align-items: center;
        color: #E1EDF7;
         a {
            text-decoration: none;
            color:#E1EDF7;
            font-family: 'Quicksand', sans-serif;
         }
    }

    .mobileMenuContainer {
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;

        .menuIcon {
            font-size: 18px;
            color: #99E0FF;
        }

    }

    @media (max-width: 700px) {
        .navBarContainer {
            display: flex;
            width: 100%;
            height: 100px;
            border: 1px solid green;

        }
        .logoContainer {
            margin-left: 24px;
            // border: 1px solid red;
            width: 10%;
            
            .logo{
                // height: 80px;
                display: none;
                // &:hover {
                //     cursor: pointer;
                // }
            }
        }

        .nameContainer {
            width: 80%;
            // border: 1px solid blue;
            display: flex;
            justify-content: center;
            align-items: center;
            .name {
                display: block;
                height: 50px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .optionsContainer {
            display: none;
        }

        .mobileMenuContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            // border: 1px solid red;
            width: 10%;
        }
    } 
}