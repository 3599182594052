.messageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;

    .messageBubble {
        display: flex;
        flex-direction: column;
        padding: 12px;
        max-width: 80%;
        border-radius: 8px;
        border-bottom-right-radius: 0;
        justify-content: flex-end;
        background-color: #285C73;

        .messageInfo {
            display: flex;
            p {
                color: #E1EDF7;
                font-family: 'Quicksand', sans-serif;
                font-weight:bold;
            }
        }
    }
}

.partnerMessageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    .partnerMessageBubble {
        display: flex;
        flex-direction: column;
        padding: 12px;
        max-width: 80%;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        justify-content: flex-start;
        background-color: #99B6CF;
        .messageInfo {

            p {
                color: #141D24;
                font-family: 'Quicksand', sans-serif;
                font-weight: bold;
            }
        }
    }
}