.foundChildDetailsPage {
    display: flex;
    flex-direction: column;
    background-color: #141D24;
    height: 100vh;

    .navBar {
        display: flex;
}

    .pageWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        padding: 24px;
    }

    .headerContainer{
        .headerText{
            font-family: 'Prata', serif;
            font-size: 24px;
            color: #E2C9C9;
            margin-bottom: 24px;

        }
    }

    .contentContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .contactInfoContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            max-width: 450px;
        }

        .learnMore {
            color: #99E0FF;
            cursor: pointer;
            text-decoration: none;
        }

        p{
            color: white;
            font-size: 16;
            font-family: 'Quicksand', sans-serif;
            text-align: center;
            line-height: 175%;
        }
    }


    .primaryButton {
        max-width: 600px;
        min-width: 150px;
        width: 100%;
        height: 64px;
        border:  none;
        border-radius: 50px;
        background-color: #285C73;
        color: #E1EDF7;
        font-size: 18px;
        font-family: 'Quicksand', sans-serif;
        font-weight: 600;
        cursor: pointer;
    }
}