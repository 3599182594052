.loadingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #141D24;
    height: 100vh;

    .spinner {
        width: 40%;
        max-width: 450px;
    }

}