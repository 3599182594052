.messagePage {
    position: relative;
    height: 100vh;
    background-color: #141D24;
    .messagePageContainer {
        padding: 24px;
        padding-bottom: 16px;
        padding-top: 48px;
        .navBar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .empty {
                width: 40px;
            }
            h3 {
                font-size: 24px;
                font-family: 'Prata', serif;
            }
            span {
                font-size: 40px;
                color: #99E0FF;
            }
        }

        .messagesContainer {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 180px);
            gap: 16px;
            overflow: scroll;
        }
    }

    .inputContainer {
        position: sticky;
        bottom: 16px;
        display: flex;
        background-color: #E1EDF7;
        height: 60px;
        border-radius: 100px;
        margin-right: 8px;
        margin-left: 8px;
        justify-content: center;
        align-items: center;

        input {
            border-radius: 100px;
            background: none;
            border: none;
            width: 100%;
            padding-left: 24px;
            width: '90%';
            height: '100%';
            outline: none;
        }

        span {
            color: #285C73;
            margin-right: 12px;
            font-size: 48px;
            margin-top: 8px;
        }
    }

    .error {
        color: red;
        display: flex;
        width: 100%;
        justify-content: center;
    }

    h1, h3, p, span {
        color: #E1EDF7;
    }
}